import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.t('services')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher, { slot: "fixed" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_refresher_content)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              ($setup.loading)
                ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (index) => {
                        return _createVNode(_component_ion_item, { key: index }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_skeleton_text)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createElementVNode("h3", null, [
                                  _createVNode(_component_ion_skeleton_text, {
                                    animated: "",
                                    style: {"width":"80%"}
                                  })
                                ]),
                                _createElementVNode("p", null, [
                                  _createVNode(_component_ion_skeleton_text, {
                                    animated: "",
                                    style: {"width":"60%"}
                                  })
                                ]),
                                _createElementVNode("p", null, [
                                  _createVNode(_component_ion_skeleton_text, {
                                    animated: "",
                                    style: {"width":"30%"}
                                  })
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1024)
                      }), 64))
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.purchasedServices, (service) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: service.id,
                          button: "",
                          detail: ""
                        }, {
                          default: _withCtx(() => [
                            (service.productImage)
                              ? (_openBlock(), _createBlock(_component_ion_thumbnail, {
                                  key: 0,
                                  slot: "start"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("img", {
                                      src: service.productImage
                                    }, null, 8, _hoisted_1)
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createElementVNode("h2", null, _toDisplayString(service.productName), 1),
                                _createElementVNode("p", null, _toDisplayString($setup.t('timesPurchased')) + _toDisplayString(service.accumulatedQty), 1),
                                _createElementVNode("p", null, _toDisplayString($setup.t('timesUsed')) + _toDisplayString(service.usedQty), 1),
                                _createElementVNode("p", null, [
                                  _createVNode(_component_ion_badge, {
                                    color: $setup.getRemainingTimesColor(service)
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('timesRemaining')) + " " + _toDisplayString(service.remainingQty), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["color"])
                                ])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
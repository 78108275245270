
// Vue reactivity
import { computed } from 'vue';

// icons
import { add } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonList, IonItem, IonLabel, IonIcon,
        IonThumbnail, IonButtons, IonButton,
        IonRefresher, IonRefresherContent, IonSkeletonText,
        IonBadge,
        modalController } from '@ionic/vue';

import BookingModal from '@/components/BookingModal.vue';

import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: 'ServiceListPage',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
                IonGrid, IonList, IonItem, IonLabel, IonIcon,
                IonThumbnail, IonButtons, IonButton,
                IonRefresher, IonRefresherContent, IonSkeletonText,
                IonBadge, },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingPurchasedServices);
    const purchasedServices = computed(() => store.state.purchasedServices);

    const openNewBookingModal = async () => {
      const modal = await modalController.create({
        component: BookingModal,
      });
      return modal.present();
    };

    const getRemainingTimesColor = (service: any) => {
      const percentage = (service.remainingQty / service.accumulatedQty) * 100;
      return percentage > 50 ? 'success' : (percentage > 0 ? 'warning' : 'danger');
    }

    // 3. return variables & methods to be used in template HTML
    return {
      t,

      // icons
      add,

      // variables
      loading, purchasedServices,

      // methods
      getRemainingTimesColor, openNewBookingModal,
    }
  }
}
